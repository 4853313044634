#visitors{
    color: $black;
    position: relative;
    padding: 206px 0;
    background-color: #FFF1F1;
    img{
        position: absolute;
    }
    .hiasan-samping{
        position: absolute;
        top: 119px;
        width: 60px;
        height: 60px;
    }
    .hiasan-samping-dua{
        right: 0;
        transform: scaleX(-1);
    }
    .container{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        h2{
            font-size: 67px;
            line-height: 101px;
            font-weight: 600;
        }
        p{
            font-weight: 500;
            font-size: 34px;
            line-height: 50px;
        }
        .text-h3{
            margin-top: 72px;
            font-weight: 600;
            font-size: 3vmax;
            line-height: 67px;
        }
        button{
            margin-top: 50px;
            padding: 16px 60px;
            background-color: #FF3700;
            border: none;
            border-radius: 15px;
            font-weight: 600;
            color: white !important;
            font-size: 21px;
            font-family: 'Montserrat', sans-serif;
        }
    }
    .gambar-bawah{
        img{
            bottom: 0 !important;
        }
        .vector-13{
            left: 0;
            width: 36%;
            height: 162px;
            z-index: 2;
        }
        .vector-14{
            left: 0;
            width: 6.2%;
            height: 162px;
            z-index: 2;
        }
        .vector-15{
            height: 100px;
            width: 10.9%;
            left: 13.5%;
            z-index: 2;
        }
        .vector-7{
            height: 77.7px;
            width: 17.2%;
            left: 30.4%;
        }
        .vector-8{
            height: 78px;
            width: 3%;
            left: 30.5%;
        }
        .vector-9{
            height: 48px;
            width: 5.1%;
            left: 37%;
        }
        // 
        .group-7{
            width: 8.8%;
            height: 30px;
            left: 45.5%;
            right: 45.5%;
        }
        .vector-10{
            height: 77.7px;
            width: 17.2%;
            right: 30.4%;
        }
        .vector-11{
            height: 78px;
            width: 3%;
            right: 30.4%;
        }
        .vector-12{
            height: 48px;
            width: 5.1%;
            right: 37%;
        }
        .vector-4{
            right: 0;
            width: 36%;
            height: 162px;
            z-index: 2;
        }
        .vector-5{
            right: 0;
            width: 6.2%;
            height: 162px;
            z-index: 2;
        }
        .vector-6{
            height: 100px;
            width: 10.9%;
            right: 13.5%;
            z-index: 2;
        }
    }
}