//
// features.scss
//

#features{
    transition-timing-function: cubic-bezier(.175,.885,.32,1.7);
    color: $black !important;
    h2{
        font-size: 34px;
        font-weight: 600;
        line-height: 50px;
    }
    p{
        font-size: 22px !important;
        font-weight: 500;
        line-height: 34px;
    }
}
.features-content {
    margin-top: 80px;
    p{
        font-weight: 600;
        font-size: 22px;
        line-height: 58px;
    }
    img{
        width: 424px;
        height: 386px;
    }
}

@media (min-width:700px) and (max-width:900px){
    #features{
        padding: 50px 0;
        h2{
            font-size: 28px !important;
            line-height: 40px !important;
        }
        p{
            font-size: 20px !important;
            line-height: 30px!important;
        }
        ul{
            margin-top: -80px;
        }
        img{
            margin-top: 30px;
            width: 450px;
            height: 450px;
        }
    }    
}

@media only screen and (max-width: 480px){ 
    #features{
        padding: 30px 0;
        h2{
            font-size: 28px !important;
            line-height: 40px !important;
        }
        p{
            font-size: 20px !important;
            line-height: 30px!important;
        }
        ul{
            margin-top: -70px;
        }
        img{
            margin-top: 20px;
            width: 250px;
            height: 250px;
        }
    }
}