#contact{
    background-color: #FFF1F1;
    width: 100%;
    max-width: 100%;
    padding: 17.5% 91px;
    color: $black;
    h2{
        text-align: center;
        font-weight: 600;
        font-size: 33.6px;
        line-height: 50.4px;
        margin-bottom: 30.8px;
    }
    h3{
        margin: 0;
        font-weight: 600;
        font-size: 22.4px;
        line-height: 33.6px;
    }
    .color-border{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 3.5px;
        background-image: linear-gradient(to right, #FF5C30,#B0B0B0);
        border-radius: 28px;
        box-shadow: 4px 4px 20px rgba(255, 95, 51, 0.35);
    }
    .big{
        max-width: 1400px;
        margin: auto;
        width: 86.4%;
        min-width: 340px;
    }
    .contact-contains{
        position: relative;
        padding: 83px 57px 85px 35px;
        width: 100%;
        background-color: $white;
        display: flex;
        // justify-content: space-between;
        justify-content: space-evenly;
        flex-wrap: wrap;
        align-items: start;
        border-radius: 28px;
        .contact-item{
            width: 24.5%;
            .contact-title{
                display: flex;
                justify-content: start;
                align-items: center;
                gap: 14px;
                img{
                    width: 25px;
                    height: 25px;
                }
            }
            p{
                margin-top: 14px;
                font-family: 500;
                font-size: 14px;
                line-height: 21px;
            }
        }
    }
    .wa{
        position: absolute;
        width: 28.5%;
        bottom: -34px;
        left: 35.75%;
        right: 35.75%;
        display: flex;
        justify-content: center;
        align-items: center;
        .wa-contains{
            width: 100%;
            padding: 17.5px 30px;
            background-color: $white;
            border-radius: 28px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 26.6px;
            img{
                width: 26px;
                height: 26px;            
            }
        }

    }
}

@media only screen and (max-width: 1335px){
    h3{
        font-size: 11px !important;
        line-height: 18px !important;
    }
}

@media only screen and (max-width: 1000px){
    .contact-contains{
        flex-direction: column;
        gap: 18px;
        .contact-item{
            width: 100% !important;
        }
    }
    .wa{
        width: 45% !important;
        left: 27.5% !important;
        right: 27.5% !important;
    }
}

@media only screen and (max-width: 600px){
    #contact{
        padding: 17.5% 0;
        .wa{
            width: 65% !important;
            left: 15.25% !important;
            right: 15.25% !important;
        }
    }
}