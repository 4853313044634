#article{   
    background-color: #FFF1F1;
    width: 100% ;
    max-width: 100%;
    padding: 157px 0;
    h2{
        color: $black;
        text-align: center;
        font-weight: 600;
        font-size: 33.6px;
        line-height: 50.4px;
    }
    .article-contains{
        margin-top: 30.8px;
        margin-left: auto;
        margin-right: auto;
        width: 88%;
        max-width: 1185px;
        display: grid;
        grid-template-columns: repeat(3, auto);
        justify-content: space-between;
        gap: 3%;
        .color-border{
            max-width: 340px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 3.5px;
            background-image: linear-gradient(to right, #FF5C30,#B0B0B0);
            border-radius: 28px;
            box-shadow: 4px 4px 20px rgba(255, 95, 51, 0.35);
            .article-card{
                width: 100%;
                height: 280px;
                background-color: $white;
                border-radius: 28px;
                .article-image{
                    position: relative;
                    width: 95%;
                    height: 139.3px;
                    margin: 14px auto;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                    .img-layer{
                        position: absolute;
                        left: 0;
                        top: 0;
                    }
                }
                h3{
                    color: $black;
                    margin: 0 14px;
                    font-weight: 500;
                    font-size: 11.2px;
                    line-height: 16.8px;
                }
                p{
                    margin: 7px 14px 0 14px;
                    color: #B0B0B0;
                    font-weight: 500;
                    font-size: 9.8px;
                    line-height: 21px;
                }
            }
        }
    }
}

@media only screen and (max-width: 1200px){
    #article .article-contains{
        grid-template-columns: repeat(2, auto);
        width: 65%;
        max-height: none;
        row-gap: 50px;
    }
}

@media only screen and (max-width: 1000px){
    #article {
        .article-contains{
            width: 657px;
        }
    }
}

@media only screen and (max-width: 710px){
    #article .article-contains{
        justify-content: center;
        width: auto;
        grid-template-columns: repeat(1, auto);
        row-gap: 20px;
    }
}