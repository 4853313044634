/* Main container for centering and background */
.separators {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center vertically */
  align-items: center; /* Center horizontally */
  width: 100%;
  min-height: 100vh; /* Full viewport height for centering */
  z-index: 2;
  padding: 0 20px; /* Padding to ensure consistent left/right spacing */
  box-sizing: border-box;
  text-align: center; /* Center all text content */
}

/* Container for the form with shadow and padding */
.containers {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; /* Center container content */
  width: 100%;
  max-width: 600px; /* Limit the container width for larger screens */
  padding: 20px;
  margin: 20px 0; /* Center the container vertically */
  border: none;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  background-color: white;
  box-sizing: border-box; /* Include padding in width calculations */
  text-align: center; /* Center text inside the container */
}

/* Form styling for inputs and buttons */
.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; /* Center all form elements */
  gap: 15px; /* Consistent gap between form elements */
  width: 100%;
  max-width: 500px;
}

/* Form fields styling with centered text */
.form-username, .form-password {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 500px;
  margin: 0 auto; /* Center the form fields horizontally */
  box-sizing: border-box; /* Include padding in width calculations */
  text-align: center; /* Center text in labels */
}

input[type="text"], input[type="password"] {
  color: #818181;
  font-weight: 500;
  font-size: 14px; /* Slightly larger font for better readability */
  padding: 12px 15px;
  width: 100%;
  max-width: 300px; /* Limit width for inputs */
  border: 2px solid #818181;
  border-radius: 0 15px 15px 15px;
  box-sizing: border-box;
  text-align: center; /* Center the input text */
}

input[type="submit"] {
  color: white;
  border: none;
  background: #ff5c30;
  width: 100%;
  max-width: 300px; /* Limit width for larger screens */
  height: 50px; /* Consistent button height */
  border-radius: 0px 15px;
  cursor: pointer;
  text-align: center; /* Center text on the button */
}

input[type="submit"]:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

/* Warning text styling */
.warning-text {
  font-size: 10px;
  font-style: italic;
  color: red;
  margin-top: 10px;
  text-align: center; /* Center warning text */
}

/* Media Queries for different screen sizes */
@media (max-width: 768px) {
  /* Tablet adjustments */
  .containers {
    padding: 15px;
  }

  .form {
    max-width: 400px; /* Adjust max-width for tablets */
  }

  input[type="text"], input[type="password"] {
    font-size: 12px; /* Smaller font for tablets */
  }

  input[type="submit"] {
    max-width: 250px;
    height: 45px;
  }
}

@media (max-width: 480px) {
  /* Mobile adjustments */
  .containers {
    padding: 10px;
  }

  .form {
    max-width: 300px; /* Further adjust max-width for mobile */
  }

  input[type="text"], input[type="password"] {
    font-size: 11px;
  }

  input[type="submit"] {
    max-width: 200px;
    height: 40px;
  }
}
